@import './base/variables';

.schedule-bgrnd {
    position: relative;
    min-height: 100vh;
    display: table;
    background-color: $off-white;
    text-align: center;
    overflow: hidden;
}

.ln-brk {
    width: 90vw;
    border-top: 3px solid $dark-grey;
    margin: auto;
    background-color: $off-white;
}

.schedule-msg {
    display: table-cell;
    vertical-align: middle;
    padding-top: 10vw;
    padding-bottom: 10vw;
    color: $dark-grey;
    object-fit: cover;
    font-family: Abhaya;
}

.schedule-txt {
    font-size: 2.5vh;
    padding-bottom: 5vh;
}

.schedule-spacing {
    height: 10vh;
}

.timeline-icon {
    height: 32px;
    width: 32px;
    text-align: center;
    margin-top: 12px;
}

.timeline-date {
    text-align: left;
}