@import './base/variables';

.hdr-msg {
    font-size: 8em;
}

.accomodations-bgrnd {
    position: relative;
    min-height: 100vh;
    display: table;
    background-color: $off-white;
    text-align: center;
}

.accomodations-page {
    display: table-cell;
    vertical-align: middle;
    font-family: 'Abhaya';
}

.accomodations-msg {
    vertical-align: middle;
    padding-top: 5vw;
    padding-bottom: 5vw;
    color: $dark-grey;
}

.accomodations-txt {
    padding-bottom: 3vh;
}

.reserved-txt {
    font-size: 1em;
    padding-bottom: 5vh;
    display: grid;
}

.container {
    padding: 0 0 0 0;
}

.accomodations-spacing {
    height: 1vh;
}

.acm-header {
    font-size: 1.5em;
}

.oacm-header {
    font-size: 1.5em;
}

.oacm-txt {
    font-size: 1em;
}

.inquire-header {
    font-size: 1em;
    padding-bottom: 4vh;
}

.ttd-block {
    padding-bottom: 5vh;
    display: grid;
}

.ttd-header {
    font-size: 1.5em;
}

.ttd-item {
    font-size: 1em;
}

@media only screen and (orientation: landscape) {
    .reserved-txt {
        font-size: 2vh;
        padding-bottom: 5vh;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(25vw, 1fr) );
    }

    .oacm-txt {
        font-size: 1.3em;
    }

    .oacm-header {
        font-size: 3em;
    }

    .ttd-block {
        padding-bottom: 5vh;
        display: grid;
        grid-template-columns: repeat( auto-fit, minmax(25vw, 1fr) );
    }

    .ttd-header {
        font-size: 2em;
    }


    .ttd-item {
        font-size: 1.3em;
    }

    .inquire-header {
        font-size: 1.7em;
    }
}


@media only screen and (orientation: portrait) {
    .reserved-item {
        padding-bottom: 2vh;
    }

    .hdr-msg {
        font-size: 3em;
    }
}