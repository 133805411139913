@import './base/variables';

.title-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-family: 'Tamoro';
    font-size: 7vmin;
    font-weight: bold;
    white-space: nowrap;
}

.title-date {
    font-size: 3vmin;
    font-family: Abhaya;
    margin-bottom: 0px;
}

.title-address {
    font-size: 2.5vmin;
    font-family: Abhaya;
    margin-bottom: 0px;
}

.rsvp-link {
    font-size: 2vmin;
    font-family: Abhaya;
    color: $white;
    border: 2px solid #fff;
}

.Landing {
    height: 100vh;
    background-image: url("../../src/resources/landing.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-align: center;
    position: relative
}