@import url(https://fonts.googleapis.com/css2?family=Homemade+Apple&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dawning+of+a+New+Day&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center;min-height:100vh;overflow:hidden;display:block;position:relative;color:#bdb1a2}
.title-text{position:absolute;top:55%;left:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);color:#fff;font-family:"Tamoro";font-size:7vmin;font-weight:bold;white-space:nowrap}.title-date{font-size:3vmin;font-family:Abhaya;margin-bottom:0px}.title-address{font-size:2.5vmin;font-family:Abhaya;margin-bottom:0px}.rsvp-link{font-size:2vmin;font-family:Abhaya;color:#fff;border:2px solid #fff}.Landing{height:100vh;background-image:url(/static/media/landing.a4ddb6a6.jpg);background-size:cover;background-repeat:no-repeat;background-position:50% 50%;text-align:center;position:relative}
@font-face{font-family:Hello-Paris;src:url("/static/media/Hello Paris Sans Regular.18f25d4b.ttf") format("truetype");font-weight:normal;font-style:normal}@font-face{font-family:Tamoro;src:url(/static/media/TamoroScript.429eb5ae.ttf) format("truetype");font-weight:normal;font-style:normal}@font-face{font-family:Abhaya;src:url(/static/media/AbhayaLibre-Medium.2fa6e039.ttf) format("truetype");font-weight:normal;font-style:normal}body{font-family:Abhaya,Lato,Tahoma,serif}.hdr-msg{font-family:Hello-Paris;font-size:7vh}.about-bgrnd{position:relative;height:100vh;display:table;background-color:#5f5d4b;text-align:center}.about-outer{display:table-cell;vertical-align:middle;height:80vh;padding:0px}.about-page{color:#f1efe8;font-family:"Lato";padding:0px}.hwm-image{visibility:hidden;max-height:0px;width:0px;display:none}.right-block{display:table-cell;vertical-align:middle}.hwm-block{padding:30px}.hwm-header{font-size:2em;vertical-align:middle;text-align:left;font-family:Hello-Paris}.hwm-text{font-size:1em}.about-ln-brk{max-width:100vw;border-top:3px solid #26201d}@media only screen and (orientation: portrait){.row{display:block;margin:0}.hwm-header{text-align:center}}@media only screen and (orientation: landscape){.about-page{display:flex;margin:auto;padding:0}.hwm-image{height:30vh;visibility:visible;max-height:100%;display:block}.left-image{height:30vh}.left-block{visibility:visible;max-height:100%;max-width:100%;flex-basis:50vw;padding-right:3vw}.right-block{padding-left:3vw}.hwm-header{font-size:5em}}
.footer-container{height:10vh;position:relative;bottom:0;width:100%;padding:12px 0 0px 5vh;background-color:#bdb1a2}.copy-txt{color:#fff}
.hdr-msg{font-size:8em}.accomodations-bgrnd{position:relative;min-height:100vh;display:table;background-color:#f1efe8;text-align:center}.accomodations-page{display:table-cell;vertical-align:middle;font-family:"Abhaya"}.accomodations-msg{vertical-align:middle;padding-top:5vw;padding-bottom:5vw;color:#26201d}.accomodations-txt{padding-bottom:3vh}.reserved-txt{font-size:1em;padding-bottom:5vh;display:grid}.container{padding:0 0 0 0}.accomodations-spacing{height:1vh}.acm-header{font-size:1.5em}.oacm-header{font-size:1.5em}.oacm-txt{font-size:1em}.inquire-header{font-size:1em;padding-bottom:4vh}.ttd-block{padding-bottom:5vh;display:grid}.ttd-header{font-size:1.5em}.ttd-item{font-size:1em}@media only screen and (orientation: landscape){.reserved-txt{font-size:2vh;padding-bottom:5vh;display:grid;grid-template-columns:repeat(auto-fit, minmax(25vw, 1fr))}.oacm-txt{font-size:1.3em}.oacm-header{font-size:3em}.ttd-block{padding-bottom:5vh;display:grid;grid-template-columns:repeat(auto-fit, minmax(25vw, 1fr))}.ttd-header{font-size:2em}.ttd-item{font-size:1.3em}.inquire-header{font-size:1.7em}}@media only screen and (orientation: portrait){.reserved-item{padding-bottom:2vh}.hdr-msg{font-size:3em}}
.schedule-bgrnd{position:relative;min-height:100vh;display:table;background-color:#f1efe8;text-align:center;overflow:hidden}.ln-brk{width:90vw;border-top:3px solid #26201d;margin:auto;background-color:#f1efe8}.schedule-msg{display:table-cell;vertical-align:middle;padding-top:10vw;padding-bottom:10vw;color:#26201d;object-fit:cover;font-family:Abhaya}.schedule-txt{font-size:2.5vh;padding-bottom:5vh}.schedule-spacing{height:10vh}.timeline-icon{height:32px;width:32px;text-align:center;margin-top:12px}.timeline-date{text-align:left}
.rsvp-bgrnd{position:relative;min-height:100vh;display:table;background-color:#5f5d4b;text-align:center;overflow:hidden}.rsvp-msg{padding:5vmin;display:table-cell;vertical-align:middle;color:#26201d}.rsvp-link{font-size:1.5vh}.iform-frame{width:600px;height:600px;margin:0 auto}
.party-bgrnd{position:relative;min-height:100vh;display:table;background-color:#bdb1a2;text-align:center;overflow:hidden}.party-title{color:#fff;padding-top:40px;font-family:Hello-Paris;font-size:7vh}.party-heading-hers{font-family:Hello-Paris;font-size:4vh;color:#fff;padding-top:30px}.party-heading-his{font-family:Hello-Paris;font-size:4vh;color:#fff}.party-grid{display:grid;grid-template-columns:repeat(auto-fit, minmax(180px, 1fr));padding:40px;grid-column-gap:auto;-webkit-column-gap:auto;column-gap:auto}.party-img{width:180px;height:180px;border-radius:50%}@media(max-width: 500px){.party-img{width:30vw;height:30vw}.party-grid{grid-template-columns:repeat(auto-fit, minmax(30vw, 1fr))}}.party-name{color:#fff;font-family:"Abhaya"}
.photos-bgrnd{position:relative;height:100vh;background-color:#bdb1a2;vertical-align:middle;display:table}.outer-page{display:table-cell;vertical-align:middle}.photos-page{vertical-align:middle;padding:3vw}.photos-crsl{padding-bottom:1.5vh;padding-top:1.5vh}@media only screen and (orientation: landscape){.photos-page{display:flex}.sbs-image{object-fit:contain;max-width:28vw;max-height:85vh}}.sbs-image{object-fit:cover}
.navbar{text-align:center;min-height:5vh;background-color:rgba(255,255,255,.7)}
