@import './base/variables';
@import './base/fonts';

.about-bgrnd {
    position: relative;
    height: 100vh;
    display: table;
    background-color: $secondary-color;
    text-align: center;
}

.about-outer {
    display: table-cell;
    vertical-align: middle;
    height: 80vh;
    padding: 0px;
}

.about-page {
    color: $off-white;
    font-family: 'Lato';
    padding: 0px;
}

.hwm-image {
    visibility: hidden;
    max-height: 0px;
    width: 0px;
    display: none;
}

.right-block {
    display: table-cell;
    vertical-align: middle;
}

.hwm-block {
    padding: 30px;
}

.hwm-header {
    font-size: 2em;
    vertical-align: middle;
    text-align: left;
    font-family: Hello-Paris;
}

.hwm-text {
    font-size: 1em;
}

.about-ln-brk {
    max-width: 100vw;
    border-top: 3px solid $dark-grey;
}

@media only screen and (orientation: portrait) {
    .row {
        display: block;
        margin: 0;
    }

    .hwm-header {
        text-align: center;
    }
}

@media only screen and (orientation: landscape) {
    .about-page {
      display: flex;
      margin: auto;
      padding: 0;
    }
    .hwm-image {
        height: 30vh;
        visibility: visible;
        max-height: 100%;
        display: block;
    }
    .left-image {
        height: 30vh;

    }
    .left-block {   
        visibility: visible;
        max-height: 100%;
        max-width: 100%;
        flex-basis: 50vw;
        padding-right: 3vw;
    }
    .right-block {
        padding-left: 3vw;
    }
    .hwm-header {
        font-size: 5em;
    }
}