@import './base/variables';

.rsvp-bgrnd {
    position: relative;
    min-height: 100vh;
    display: table;
    background-color: $secondary-color;
    text-align: center;
    overflow: hidden;
}

.rsvp-msg {
    padding: 5vmin;
    display: table-cell;
    vertical-align: middle;
    color: $dark-grey;
}

.rsvp-link {
    font-size: 1.5vh;
}

.iform-frame {
    width: 600px;
    height: 600px;
    margin: 0 auto;
}