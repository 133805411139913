@import './base/variables';


.footer-container {
    height: 10vh;
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 12px 0 0px 5vh;
    background-color: $primary-color;
}

.copy-txt {
    color: $white;
}
