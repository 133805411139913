$primary-color: #BDB1A2;
$primary-color-accent: #24aee4;
$primary-color-shadow: #0a96ce;
$secondary-color: #5F5D4B;
$secondary-color-accent: #0161ce;
$accent-color: #091e42;
$white: #FFFFFF;
$dark-grey: #26201D;
$footer-color: #054d9e;
$header-color: #054d9ef2;
$off-white: #F1EFE8;
$light-gray: #EAEAEA;