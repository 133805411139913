@import './base/variables';

.photos-bgrnd {
    position: relative;
    height: 100vh;
    background-color: $primary-color;
    vertical-align: middle;
    display: table;
}

.outer-page {
    display: table-cell;
    vertical-align: middle;
}

.photos-page {  
    vertical-align: middle;
    padding: 3vw;
}

.photos-crsl {
    padding-bottom: 1.5vh;
    padding-top: 1.5vh;
}

@media only screen and (orientation: landscape) {
    .photos-page {
        display: flex;
    }

    .sbs-image {
        object-fit: contain;
        max-width: 28vw;
        max-height: 85vh;
    }
}

.sbs-image {
    object-fit: cover;
}